import { Theme } from '../../../store/reducers/themeReducer';
import googleMapStyles from '../../map/googleMapStyles.json';
import nikeLoadingAnimation from '../../themes/nike/loading.json';
import NikeShuttleRoundedSvg from '../../../assets/nike/nike-shuttle-rounded.svg';
import NikeShuttleSvg from '../../../assets/nike/nike-shuttle.svg';
import NikeLogoPng from '../../../assets/nike/nike-logo-dark.png';

export const THEME: Theme = {
  name: 'nike',
  map: googleMapStyles,
  loadingMask: JSON.stringify(nikeLoadingAnimation),
  colors: {
    primary: '#000',
    primaryRgb: '0, 0, 0',
    secondary: '#75787B',
    secondaryRgb: '117,120,123',
    accent: '#74479f',
  },
  icons: {
    fixedRouteVehicleRounded: NikeShuttleRoundedSvg,
    fixedRouteVehicle: NikeShuttleSvg,
  },
  logo: NikeLogoPng,
  favicon: 'nike-favicon.png',
  requireCssFilePath: () => require('./nike.css'),
  longWalkTranslationKey: 'trip:longWalkWarningTooltip',
};
