import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';

import store from './store/global';
import HailerContextProvider from './context/HailerProvider';
import PusherProvider from './context/PusherProvider';
import ConfigProvider from './context/ConfigProvider';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider hostname={new URL(window.location.href).hostname}>
      <PusherProvider>
        <Provider store={store}>
          <HailerContextProvider>
            <App />
          </HailerContextProvider>
        </Provider>
      </PusherProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
