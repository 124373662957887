import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../global';

export interface Alert {
  id: string;
  title: string;
  message: string;
  type: string;
}

interface AlertsState {
  alerts: Alert[];
  loading: boolean;
  error: string | null;
}

const initialState: AlertsState = {
  alerts: [],
  loading: false,
  error: null,
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    fetchAlertsStart: (state: AlertsState) => {
      state.loading = true;
      state.error = null;
    },
    fetchAlertsSuccess: (state: AlertsState, action: PayloadAction<Alert[]>) => {
      state.alerts = action.payload;
      state.loading = false;
    },
    fetchAlertsFailure: (state: AlertsState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchAlertsStart, fetchAlertsSuccess, fetchAlertsFailure } = alertsSlice.actions;

export const selectAlerts = (state: RootState) => state.alerts.alerts;
export const selectAlertsLoading = (state: RootState) => state.alerts.loading;
export const selectAlertsError = (state: RootState) => state.alerts.error;

export default alertsSlice.reducer;
