import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OnDemandServiceSettingsPayload, FixedRouteServiceSettingsPayload } from '@liftango/liftango-client';
import type { RootState } from '../global';

export type ServiceState = {
  hasListInitialised: boolean;
  list: (OnDemandServiceSettingsPayload | FixedRouteServiceSettingsPayload)[];
};

const initialState: ServiceState = {
  hasListInitialised: false,
  list: [],
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServices: (state: ServiceState, action: PayloadAction<(OnDemandServiceSettingsPayload | FixedRouteServiceSettingsPayload)[]>) => {
      state.list = action.payload;
      state.hasListInitialised = true;
    },
  },
});

export const { setServices } = serviceSlice.actions;

export const selectServices = (state: RootState) => state.service.list;

export const selectServiceInitialisationState = (state: RootState) => state.service.hasListInitialised;

export default serviceSlice.reducer;
