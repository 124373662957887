import { Persistence } from './persistence';

export class LocalStorage implements Persistence {
  public async setItem(key: string, value: string): Promise<void> {
    this.setItemSync(key, value);
  }
  public async getItem(key: string): Promise<string | null> {
    return this.getItemSync(key);
  }
  public async removeItem(key: string): Promise<void> {
    this.removeItemSync(key);
  }
  public setItemSync(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
  public getItemSync(key: string): string | null {
    return localStorage.getItem(key);
  }
  public removeItemSync(key: string): void {
    localStorage.removeItem(key);
  }
}
