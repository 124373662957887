import { Theme } from '../../../store/reducers/themeReducer';
import googleMapStyles from '../../map/googleMapStyles.json';
import Logo from '../../../assets/glenfarg/glenfarg-logo.png';

export const THEME: Theme = {
  name: 'glenfarg',
  map: googleMapStyles,
  colors: {
    primary: '#000',
    primaryRgb: '0, 0, 0',
    secondary: '#75787B',
    secondaryRgb: '117,120,123',
    accent: '#74479f',
  },
  logo: Logo,
  favicon: 'glenfarg-favicon.ico',
  requireCssFilePath: () => require('./glenfarg.css'),
};
