import React, { createContext, Context, useContext } from 'react';
import { HailerOpsClient, ALLOWED_ENV } from '@liftango/ops-client';
import { useAppSelector } from '../hooks/storeHooks';
import { selectApplicationMode } from '../store/reducers/applicationReducer';
import { Persistence } from '../storage/persistence';
import { LocalStorage } from '../storage/local-storage';
import { ConfigContext } from './ConfigProvider';

const ENV_MAP: Record<string, ALLOWED_ENV> = {
  development: 'dev',
  production: 'prod',
};

const defaultContext: any = null;
export const HailerContext: Context<HailerOpsClient> = createContext<HailerOpsClient>(defaultContext);

type HailerContextProviderProps = {
  children: any;
};

const HailerContextProvider = ({ children }: HailerContextProviderProps) => {
  const { apiUrl } = useContext(ConfigContext);
  const application = useAppSelector(selectApplicationMode);

  const persistence: Persistence = new LocalStorage();
  const hailerService = new HailerOpsClient(
    apiUrl,
    ENV_MAP[process.env.NODE_ENV] || process.env.NODE_ENV || 'dev',
    {
      type: 'http',
    },
    persistence,
    application,
  );

  return <HailerContext.Provider value={hailerService}>{children}</HailerContext.Provider>;
};

export default HailerContextProvider;
