import { Theme } from '../../../store/reducers/themeReducer';
import googleMapStyles from '../../map/googleMapStyles.json';
import Logo from '../../../assets/perrone-robotics/theconnect-logo.png';

export const THEME: Theme = {
  name: 'perrone-robotics',
  map: googleMapStyles,
  colors: {
    primary: '#ee7334',
    primaryRgb: '238, 115, 52',
    secondary: '#3a3a48',
    secondaryRgb: '58, 58, 72',
    accent: '#74479f',
  },
  logo: Logo,
  favicon: 'theconnect-favicon.ico',
  requireCssFilePath: () => require('../default/default.css'),
};
