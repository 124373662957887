import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enCommon from './locales/en/common.json';
import enBooking from './locales/en/booking.json';
import enTrip from './locales/en/trip.json';
import enHome from './locales/en/home.json';

import frCommon from './locales/fr/common.json';
import frTrip from './locales/fr/trip.json';

import testCommon from './locales/test/common.json';

export const resources = {
  en: {
    common: enCommon,
    booking: enBooking,
    trip: enTrip,
    home: enHome,
  },
  // update this when we have the real translations from transifex
  ...(window.location.hostname.includes('qa') || window.location.hostname === 'localhost'
    ? {
        fr: {
          common: frCommon,
          trip: frTrip,
        },
        test: {
          common: testCommon,
        },
      }
    : {}),
} as const;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'common',
    ns: ['common', 'booking', 'trip', 'home'],
    resources,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
