import React, { ReactNode, useContext } from 'react';
import { PusherProvider as UsePusherProvider } from '@harelpls/use-pusher';
import { ConfigContext } from './ConfigProvider';

type PusherProviderProps = {
  children: ReactNode;
};

const PusherProvider = (props: PusherProviderProps) => {
  const { pusherCluster, pusherKey } = useContext(ConfigContext);

  return (
    <UsePusherProvider cluster={pusherCluster} clientKey={pusherKey}>
      {props.children}
    </UsePusherProvider>
  );
};

export default PusherProvider;
