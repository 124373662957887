import React, { ReactNode, createContext, useMemo } from 'react';
import { ApplicationMode } from '../store/reducers/applicationReducer';
import { ThemeName } from '../store/reducers/themeReducer';

type ConfigProviderProps = {
  hostname: string;
  children: ReactNode;
};

type AppConfig = {
  apiUrl: string;
  networkId: string;
  appTheme: ThemeName;
  appMode: ApplicationMode;
  appTitle: string;
  googleMapsApiKey: string;
  useGoogleMapsAddresses: boolean;
  pusherCluster: string | undefined;
  pusherKey: string | undefined;
};

export const ConfigContext = createContext<AppConfig>({
  networkId: '',
  apiUrl: '',
  appTheme: 'liftango',
  appMode: 'fixed_route',
  appTitle: '',
  googleMapsApiKey: '',
  useGoogleMapsAddresses: false,
  pusherCluster: undefined,
  pusherKey: undefined,
});

const defaultAppConfig: AppConfig = {
  networkId: '',
  apiUrl: 'https://hailer-odb-dev.liftango.com',
  appTheme: 'liftango',
  appMode: 'fixed_route',
  appTitle: 'Liftango On-Demand',
  googleMapsApiKey: 'AIzaSyDN_50bgnHhCab7TrHwapUC6CbtxhEK8nQ',
  useGoogleMapsAddresses: true,
  pusherCluster: 'ap4',
  pusherKey: '40669126bc4c9c25caf1',
};

const networkConfig: { [key: string]: Partial<AppConfig> } = {
  localhost: {
    networkId: process.env.REACT_APP_NETWORK_ID ?? '',
    apiUrl: process.env.REACT_APP_HAILER_BASE_URL ?? '',
    appTheme: (process.env.REACT_APP_THEME as ThemeName) ?? 'liftango',
    appMode: (process.env.REACT_APP_APPLICATION_MODE as ApplicationMode) ?? 'fixed_route',
    appTitle: process.env.REACT_APP_TITLE ?? 'Liftango On-Demand',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
    useGoogleMapsAddresses: process.env.REACT_APP_USE_GOOGLE_MAPS_ADDRESSES === 'true',
    pusherCluster: process.env.REACT_APP_PUSHER_CLUSTER ?? 'ap4',
    pusherKey: process.env.REACT_APP_PUSHER_KEY,
  },
  'lifty-lite-dev': {
    networkId: '91702a7c-9a3c-4539-b24a-c5a52fec1819',
  },
  'lifty-lite-qa': {
    networkId: '91702a7c-9a3c-4539-b24a-c5a52fec1819',
    apiUrl: 'https://hailer-qa.liftango.com',
    googleMapsApiKey: 'AIzaSyB_N86K8HCeRgk8W_oDgts4Ghqx3uvSqF8',
    pusherKey: 'de14beaab6cdacfa2cb6',
  },
  'lifty-lite-uat': {
    networkId: '91702a7c-9a3c-4539-b24a-c5a52fec1819',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  a1parkingshuttle: {
    networkId: '02bb7c2b-545a-49cf-be11-7a31571ed742',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'A1 Parking Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'a1parkingshuttle-uat': {
    networkId: '02bb7c2b-545a-49cf-be11-7a31571ed742',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'A1 Parking Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'abbotsford-airport': {
    networkId: 'd2cb7bec-17b1-46ab-a7f9-e3f271ce004b',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Abbotsford Airport',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'abbotsford-airport-uat': {
    networkId: 'd2cb7bec-17b1-46ab-a7f9-e3f271ce004b',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Abbotsford Airport',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'aladdin-airport-shuttle': {
    networkId: 'a483f84d-827a-43c5-94b6-ecca30516f2a',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Aladdin Airport Shuttle',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'aldar-dubai': {
    networkId: 'aa670564-b5f8-48ce-a5e1-b71dc5655ec3',
    apiUrl: 'https://hailer-odb-prod-euwe2.liftango.com',
    appTitle: 'ALDAR',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'aldar-dubai-uat': {
    networkId: 'aa670564-b5f8-48ce-a5e1-b71dc5655ec3',
    apiUrl: 'https://hailer-odb-uat-euwe2.liftango.com',
    appTitle: 'ALDAR',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'at-local-fixed-route-uat': {
    networkId: 'f142b8ab-079e-4660-8f05-f39642e8d616',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'AT Local',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  bna9: {
    networkId: 'af9b713b-374d-433c-81da-cc91d433f705',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Amazon BNA9',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'bna9-uat': {
    networkId: 'af9b713b-374d-433c-81da-cc91d433f705',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Amazon BNA9',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  bwi2: {
    networkId: '0b52fcab-7315-4980-982f-217929b22fbb',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Amazon BWI2',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'bwi2-uat': {
    networkId: '0b52fcab-7315-4980-982f-217929b22fbb',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Amazon BWI2',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'city-school-prod': {
    networkId: '0f05c665-903d-433e-87ed-0774dbf3a4d4',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'City School',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'campbells-whq': {
    networkId: 'b0556fbc-9886-454c-a0b1-c51779fb5c01',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Campbell’s WHQ',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  cooper: {
    networkId: 'dab10744-05c3-4273-bb37-230751b8396a',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Cooper Hospital',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'csumb-prod': {
    networkId: '9338bd0b-fef0-44c0-99c4-e6865f83803a',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'CSUMB',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'csumb-uat': {
    networkId: '9338bd0b-fef0-44c0-99c4-e6865f83803a',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'CSUMB',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  dvc4: {
    networkId: 'a3281926-04a1-4cc8-b4dc-2b899081c669',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Amazon DVC4',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'dvc4-uat': {
    networkId: 'a3281926-04a1-4cc8-b4dc-2b899081c669',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Amazon DVC4',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  dvc6: {
    networkId: 'c109acc6-05d4-4359-9c50-09bca3d972b2',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Amazon DVC6',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'dvc6-uat': {
    networkId: 'c109acc6-05d4-4359-9c50-09bca3d972b2',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Amazon DVC6',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'fulton-labs': {
    networkId: '94465aa7-7754-408b-8ecd-0c1ff1cc74e1',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Fulton Labs',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'fulton-labs-uat': {
    networkId: '94465aa7-7754-408b-8ecd-0c1ff1cc74e1',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Fulton Labs',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'hallandale-shuttle': {
    networkId: '4c353c93-1744-439b-937d-034c407f08e8',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Hallandale Shuttle',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'hilton-blue-lagoon': {
    networkId: '7e7f815e-4f1a-475c-bf84-6bf6d2ebb7e0',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Hilton Blue Lagoon Miami Airport',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'jhh-shuttle-bus-service': {
    networkId: '4e46d477-3805-4919-b804-c3231dc350d8',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'JHH Shuttle Bus Service',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'lax-105': {
    networkId: '3d80115e-737d-49c3-9c1e-b0f47e279831',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'LAX 105 Shuttle',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'lax-105-uat': {
    networkId: '3d80115e-737d-49c3-9c1e-b0f47e279831',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'LAX 105 Shuttle',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'lax-shuttle-prod': {
    networkId: 'f4366897-b601-46c4-89cf-31c6503894b9',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'LAX Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'lax-shuttle-uat': {
    networkId: 'f4366897-b601-46c4-89cf-31c6503894b9',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'LAX Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'lucketts-shuttle': {
    networkId: 'b4268f24-2a4b-43aa-937c-b810231f95f8',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Lucketts Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'lucketts-shuttle-uat': {
    networkId: 'b4268f24-2a4b-43aa-937c-b810231f95f8',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Lucketts Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'mount-sinai': {
    networkId: 'd6b68637-b182-4e94-973a-64f28cac2d08',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Mount Sinai',
    appTheme: 'mount-sinai',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'mount-sinai-uat': {
    networkId: 'd6b68637-b182-4e94-973a-64f28cac2d08',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Mount Sinai',
    appTheme: 'mount-sinai',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'nike-suttle': {
    networkId: 'f27748f0-8acd-4e8b-85ad-2b4563ca495b',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Nike Shuttle',
    appTheme: 'nike',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'nike-shuttle-prod': {
    networkId: 'f27748f0-8acd-4e8b-85ad-2b4563ca495b',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Nike Shuttle',
    appTheme: 'nike',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'nike-shuttle-uat': {
    networkId: 'f27748f0-8acd-4e8b-85ad-2b4563ca495b',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Nike Shuttle',
    appTheme: 'nike',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  theconnect: {
    // Perrone Robotics
    networkId: 'd5d09fb0-6dea-44b7-97db-79121c9db788',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'The Connect',
    appTheme: 'perrone-robotics',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'perrone-robotics-uat': {
    networkId: 'd5d09fb0-6dea-44b7-97db-79121c9db788',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'The Connect',
    appTheme: 'perrone-robotics',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  the55bus: {
    networkId: '5804c4b9-684a-43ae-af85-4af27cbdb5db',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'The 55 Bus',
    appTheme: 'glenfarg',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'tvp-shuttle': {
    networkId: '625c852d-97c7-41d6-9e14-7d50790abdc2',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Thames Valley Park Shuttle Bus',
    appTheme: 'tvp',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'tvp-shuttle-uat': {
    networkId: '625c852d-97c7-41d6-9e14-7d50790abdc2',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Thames Valley Park Shuttle Bus',
    appTheme: 'tvp',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  trio: {
    networkId: '12c52930-5e36-4034-b9b8-fe1176d859a2',
    apiUrl: 'https://hailer-odb-prod-euwe2.liftango.com',
    appTitle: 'TRIO',
    appTheme: 'trio',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'trio-uat': {
    networkId: '12c52930-5e36-4034-b9b8-fe1176d859a2',
    apiUrl: 'https://hailer-odb-uat-euwe2.liftango.com',
    appTitle: 'TRIO',
    appTheme: 'trio',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  uhnbc: {
    networkId: '38216586-29c9-48ed-96f5-f0c00a2034dd',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'UHNBC',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'walmart-shuttle': {
    networkId: '6c5302e5-129a-4b37-8560-cdceab1600ff',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Walmart Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'walmart-shuttle-prod': {
    networkId: '6c5302e5-129a-4b37-8560-cdceab1600ff',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Walmart Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'walmart-shuttle-uat': {
    networkId: '6c5302e5-129a-4b37-8560-cdceab1600ff',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Walmart Shuttle',
    useGoogleMapsAddresses: false,
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  waterside: {
    networkId: '1e6ede65-edde-4a34-87bf-da93935c390a',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Waterside Plaza',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  wcsu: {
    networkId: '02c7e08b-31a6-4256-a493-bd09045c4ff5',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Western State CT University',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  'wcsu-uat': {
    networkId: '02c7e08b-31a6-4256-a493-bd09045c4ff5',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Western State CT University',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  wptrack: {
    networkId: 'de76e7a9-e0b4-4f36-908a-84db0c50c558',
    apiUrl: 'https://hailer-odb-prod-euwe2.liftango.com',
    appTitle: 'WPTrack Shuttle',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  ygk1: {
    networkId: 'ad2bcd7a-2158-4280-abc1-ff15d37efdda',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Amazon YGK1',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'ygk1-uat': {
    networkId: 'ad2bcd7a-2158-4280-abc1-ff15d37efdda',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Amazon YGK1',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  yvr4: {
    networkId: '2609bd83-6be3-48d6-b915-731460d81917',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Amazon YVR4',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'yvr4-uat': {
    networkId: '2609bd83-6be3-48d6-b915-731460d81917',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Amazon YVR4',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
  yxx2: {
    networkId: '6ced1014-1334-4b45-949b-2d0792f5eb18',
    apiUrl: 'https://hailer-odb-prod.liftango.com',
    appTitle: 'Amazon YXX2',
    pusherKey: 'a3dd7ec3dba6f67900f3',
  },
  'yxx2-uat': {
    networkId: '6ced1014-1334-4b45-949b-2d0792f5eb18',
    apiUrl: 'https://hailer-odb-uat.liftango.com',
    appTitle: 'Amazon YXX2',
    pusherKey: '6d3c620d2ad03f3fc815',
  },
};

const ConfigProvider = (props: ConfigProviderProps) => {
  const config: AppConfig = useMemo(() => {
    const parts: string[] = props.hostname.split('.');
    const subdomain: string = parts.length >= 3 ? parts[0] : 'localhost';

    if (subdomain === 'localhost') {
      console.warn('Sudbdomain Config resolved to localhost');
    }

    return {
      ...defaultAppConfig,
      ...networkConfig[subdomain],
    };
  }, [props.hostname]);

  return <ConfigContext.Provider value={config}>{props.children}</ConfigContext.Provider>;
};

export default ConfigProvider;
