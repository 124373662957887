import React, { Dispatch, Reducer, useContext, useEffect, useReducer } from 'react';
import { IonApp, setupIonicReact } from '@ionic/react';
import { addDays, format } from 'date-fns';
import { FixedRoute } from '@liftango/ops-client';
import { useLoadScript } from '@react-google-maps/api';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/themes/default/default.css';

/* Font awesome library that contains selected icons for use */
import './fontAwesomeLibrary';

import Router from './router/Router';
import { selectTheme, setTheme, Theme, ThemeName } from './store/reducers/themeReducer';
import { useAppDispatch, useAppSelector } from './hooks/storeHooks';
import { selectNetwork, setNetwork } from './store/reducers/networkReducer';
import Loading from './containers/Loading/Loading';
import { useHailerService } from './services/HailerService';

import { setApplicationMode } from './store/reducers/applicationReducer';
import { selectServiceInitialisationState, setServices } from './store/reducers/serviceReducer';
import { setHiddenRoutes, setRoutes } from './store/reducers/routesReducer';
import { MapAction, mapInitialState, mapReducer, MapReducerState } from './components/features/Map/mapReducer';
import { MapContext, MapContextProps } from './context/MapProvider';
import { ConfigContext } from './context/ConfigProvider';
import { LocalStorage } from './storage/local-storage';
import { Persistence } from './storage/persistence';

import i18n from './i18n';

setupIonicReact();

// this must not be a new array each render
const googleMapsLibraries: ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[] = ['places'];

const App: React.FC = () => {
  const { networkId, appTheme, appMode, googleMapsApiKey, appTitle } = useContext(ConfigContext);
  const hailerService = useHailerService();
  const persistence: Persistence = new LocalStorage();
  const theme: Theme = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();
  const network = useAppSelector(selectNetwork);

  useEffect(() => {
    const urlLanguage = new URLSearchParams(window.location.search).get('lng');
    const navigatorLanguage = navigator.language;
    const networkLanguage = network?.locale;
    i18n.changeLanguage(urlLanguage || navigatorLanguage || networkLanguage || 'en');
  }, [window.location.search, navigator.language, network]);

  useEffect(() => {
    if (appTheme) {
      dispatch(setTheme(appTheme));
    }
  }, [appTheme]);

  useEffect(() => {
    if (appMode) {
      dispatch(setApplicationMode(appMode));
    }
  }, [appMode]);

  /**
   * App title needs to be set here after reading the config
   * since we are not building multiple apps now with the title as an ENV variable
   */
  useEffect(() => {
    if (appTitle) {
      document.title = appTitle;
      document.querySelector('meta[name="apple-mobile-web-app-title"]')?.setAttribute('content', appTitle);
    }
  }, [appTitle]);

  useEffect(() => {
    const favicon: HTMLLinkElement = document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = `assets/icon/${theme.favicon}`;
    }
  }, [theme]);

  useEffect(() => {
    if (appMode && networkId) {
      hailerService.Kiosk.Networks.getNetworkDetailed(networkId)
        .then((result) => {
          const startDate: string = format(new Date(), 'yyyy-MM-dd');
          const endDate: string =
            'dateRideRestriction' in result.entry.settings && result.entry.settings.dateRideRestriction > 0
              ? format(addDays(new Date(), result.entry.settings.dateRideRestriction), 'yyyy-MM-dd')
              : startDate;

          if (appMode === 'fixed_route') {
            Promise.allSettled([
              hailerService.FixedRoute.Service.getServiceSettings(result.entry.id, startDate, endDate),
              hailerService.FixedRoute.Service.getServiceRoutesAndStops(result.entry.id),
            ]).then((results) => {
              if (results[0].status === 'fulfilled') {
                dispatch(setServices(results[0].value.serviceSettings));
              }

              if (results[1].status === 'fulfilled') {
                const routes: FixedRoute.ServiceRoutesAndStopsPayload[] = results[1].value.routes;
                const persistedHiddenRoutes: string[] = JSON.parse(persistence.getItemSync('hiddenRoutes') || '[]');
                // Remove blacklist if all routes are filtered out - e.g. if their unhidden routes could have been removed from service
                const initialHiddenRoutes: string[] = routes.some((route) => !persistedHiddenRoutes.includes(route.id))
                  ? persistedHiddenRoutes
                  : [];

                dispatch(setRoutes(routes));
                dispatch(setHiddenRoutes(initialHiddenRoutes));
              }
            });
          } else {
            hailerService.Kiosk.Service.getServiceSettings(result.entry.id, startDate, endDate).then((result) => {
              dispatch(setServices(result.serviceSettings));
            });
          }

          dispatch(setNetwork(result.entry));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [appMode, networkId]);

  // Require the theme based on its name
  theme.requireCssFilePath();

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
    libraries: googleMapsLibraries,
  });

  const [mapState, mapDispatch]: [MapReducerState, Dispatch<MapAction>] = useReducer<Reducer<MapReducerState, MapAction>>(
    mapReducer,
    mapInitialState,
  );
  const mapContextReducerState: MapContextProps = {
    mapState: mapState,
    dispatch: mapDispatch,
  };

  // disable dark mode until we finalise the theme
  // const themeScheme: ThemeScheme = useAppSelector(selectThemeScheme);
  // useThemeScheme(themeScheme);

  const hasServiceInitialised = useAppSelector(selectServiceInitialisationState);
  if (!network || !hasServiceInitialised || !isLoaded) {
    return <Loading />;
  }

  return (
    <IonApp>
      <MapContext.Provider value={mapContextReducerState}>
        <Router />
      </MapContext.Provider>
    </IonApp>
  );
};

export default App;
