import { FixedRoute } from '@liftango/ops-client';

export type ProductTourPage = 'opening' | 'route-filter' | 'shuttles-stops' | 'plan-journey' | null;

export type MapReducerState = {
  map: google.maps.Map | null;
  selectedShiftSolutionId: string | null;
  selectedStop: FixedRoute.ServiceRouteStop | null;
  selectedRouteId: string | null;

  // Null if tour closed. 'opening' exists to trigger product tour to open, which then updates state with the first page.
  // This avoids two sources of truth for the page order, which can live in ProductTour where it's most relevant.
  productTourPage: ProductTourPage;
};

export const mapInitialState: MapReducerState = {
  map: null,
  selectedShiftSolutionId: null,
  selectedStop: null,
  selectedRouteId: null,
  productTourPage: null,
};

export type MapAction =
  | {
      type: 'SET_MAP';
      payload: google.maps.Map | null;
    }
  | {
      type: 'RESET_SELECTIONS';
    }
  | {
      type: 'SET_SELECTED_SHIFT_SOLUTION_ID';
      payload: string | null;
    }
  | {
      type: 'SELECT_SHIFT_SOLUTION_ID';
      payload: string | null;
    }
  | {
      type: 'SET_SELECTED_STOP';
      payload: FixedRoute.ServiceRouteStop | null;
    }
  | {
      type: 'SELECT_STOP';
      payload: FixedRoute.ServiceRouteStop | null;
    }
  | {
      type: 'SET_SELECTED_ROUTE_ID';
      payload: string | null;
    }
  | {
      type: 'SELECT_ROUTE_ID';
      payload: string | null;
    }
  | {
      type: 'SET_PRODUCT_TOUR_PAGE';
      payload: ProductTourPage;
    }
  | {
      type: 'OPEN_PRODUCT_TOUR';
    };

export const mapReducer = (state: MapReducerState, action: MapAction): MapReducerState => {
  switch (action.type) {
    case 'SET_MAP':
      return {
        ...state,
        map: action.payload,
      };
    case 'RESET_SELECTIONS':
      return {
        ...state,
        selectedShiftSolutionId: null,
        selectedStop: null,
        selectedRouteId: null,
      };
    case 'SET_SELECTED_SHIFT_SOLUTION_ID':
      return {
        ...state,
        selectedShiftSolutionId: action.payload,
      };
    case 'SELECT_SHIFT_SOLUTION_ID':
      return {
        ...state,
        selectedShiftSolutionId: action.payload,
        selectedStop: null,
        selectedRouteId: null,
      };
    case 'SET_SELECTED_STOP':
      return {
        ...state,
        selectedStop: action.payload,
      };
    case 'SELECT_STOP':
      return {
        ...state,
        selectedShiftSolutionId: null,
        selectedStop: action.payload,
        selectedRouteId: null,
      };
    case 'SET_SELECTED_ROUTE_ID':
      return {
        ...state,
        selectedRouteId: action.payload,
      };
    case 'SELECT_ROUTE_ID':
      return {
        ...state,
        selectedShiftSolutionId: null,
        selectedStop: null,
        selectedRouteId: action.payload,
      };
    case 'SET_PRODUCT_TOUR_PAGE':
      return {
        ...state,
        productTourPage: action.payload,
      };
    case 'OPEN_PRODUCT_TOUR':
      return {
        ...state,
        productTourPage: 'opening',
      };
    default:
      return state;
  }
};
