import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Kiosk } from '@liftango/ops-client';
import type { RootState } from '../global';

export type Network = Kiosk.NetworkDetailed;

export type NetworkState = {
  network: Network | null;
};

const initialState: NetworkState = {
  network: null,
};

export const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setNetwork: (state: NetworkState, action: PayloadAction<Kiosk.NetworkDetailed>) => {
      state.network = action.payload;
    },
  },
});

export const { setNetwork } = networkSlice.actions;

export const selectNetwork = (state: RootState) => state.network.network;

export const selectNetworkEnforced = (state: RootState) => {
  if (!state.network.network) {
    throw new Error('test');
  }

  return state.network.network;
};

export default networkSlice.reducer;
