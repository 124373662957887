import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../global';
import { THEME as DEFAULT_THEME } from '../../theme/themes/default/theme';
import { THEME as NIKE_THEME } from '../../theme/themes/nike/theme';
import { THEME as WALMART_THEME } from '../../theme/themes/walmart/theme';
import { THEME as TVP_THEME } from '../../theme/themes/tvp/theme';
import { THEME as MOUNT_SINAI_THEME } from '../../theme/themes/mount-sinai/theme';
import { THEME as TRIO_THEME } from '../../theme/themes/trio/theme';
import { THEME as GLENFARG_THEME } from '../../theme/themes/glenfarg/theme';
import { THEME as PERRONE_ROBOTICS_THEME } from '../../theme/themes/perrone-robotics/theme';

export type ThemeScheme = 'light' | 'dark';

export type ThemeName = 'liftango' | 'nike' | 'walmart' | 'tvp' | 'mount-sinai' | 'trio' | 'glenfarg' | 'perrone-robotics';

export type Theme = {
  name: ThemeName;
  map: any;
  colors: ThemeColors;
  logo: string;
  favicon: string;
  requireCssFilePath: () => NodeRequire;
  loadingMask?: string;
  icons?: ThemeIcons;
  distinguishInactiveRoutes?: boolean;
  longWalkTranslationKey?: string;
};

export type ThemeColors = {
  primary: string;
  primaryRgb: string;
  secondary: string;
  secondaryRgb: string;
  accent: string;
};

export type ThemeState = {
  scheme: ThemeScheme;
  themeList: Theme[];
  theme: Theme;
};

export type ThemeIcons = {
  fixedRouteVehicleRounded?: string;
  fixedRouteVehicle?: string;
};

export type AlertStyles = {
  informationBackgroundColour: string;
  warningBackgroundColour: string;
  errorBackgroundColour: string;
  textColourLight: string;
  textColourDark: string;
};

const initialState: ThemeState = {
  scheme: 'light',
  themeList: [DEFAULT_THEME, NIKE_THEME, WALMART_THEME, TVP_THEME, MOUNT_SINAI_THEME, TRIO_THEME, GLENFARG_THEME, PERRONE_ROBOTICS_THEME],
  theme: DEFAULT_THEME,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setScheme: (state: ThemeState, action: PayloadAction<ThemeScheme>) => {
      state.scheme = action.payload;
    },
    setTheme: (state: ThemeState, action: PayloadAction<ThemeName>) => {
      state.theme = state.themeList.find((theme: Theme) => theme.name === action.payload) ?? DEFAULT_THEME;
    },
  },
});

export const { setScheme, setTheme } = themeSlice.actions;

export const selectTheme = (state: RootState) => state.theme.theme;
export const selectThemeScheme = (state: RootState) => state.theme.scheme;

export default themeSlice.reducer;
