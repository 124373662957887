import React from 'react';
import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';
import LottiePlayer from 'lottie-react';
import { selectTheme, Theme } from '../../store/reducers/themeReducer';
import { useAppSelector } from '../../hooks/storeHooks';

type LoadingProps = {
  className?: string;
};

const Loading = (props: LoadingProps) => {
  const theme: Theme = useAppSelector(selectTheme);

  return (
    <StyledLoading className={props.className}>
      {theme.loadingMask ? (
        <StyledLoaderWrapper>
          <LottiePlayer animationData={JSON.parse(theme.loadingMask)} />
        </StyledLoaderWrapper>
      ) : (
        <IonSpinner name="crescent" color="light" />
      )}
    </StyledLoading>
  );
};

const StyledLoading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lifty-loading--background-color);

  ion-spinner {
    width: 5rem;
    height: 5rem;
  }
`;

const StyledLoaderWrapper = styled.div`
  width: 5rem;
  height: 5rem;
`;

export default Loading;
