import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import applicationReducer from './reducers/applicationReducer';
import networkReducer from './reducers/networkReducer';
import serviceReducer from './reducers/serviceReducer';
import themeReducer from './reducers/themeReducer';
import routesReducer from './reducers/routesReducer';
import alertsReducer from './reducers/alertsReducer';

const store = configureStore({
  reducer: {
    application: applicationReducer,
    network: networkReducer,
    service: serviceReducer,
    routes: routesReducer,
    theme: themeReducer,
    alerts: alertsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {theme: ThemeState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
