import React, { Suspense, lazy } from 'react';
import { useAppSelector } from '../hooks/storeHooks';
import { selectApplicationMode } from '../store/reducers/applicationReducer';
import Loading from '../containers/Loading/Loading';
import { IonReactRouter } from '@ionic/react-router';

const KioskRoutes = lazy(() => import('./Kiosk/KioskRoutes'));
const FixedRoutes = lazy(() => import('./FixedRoute/FixedRoutes'));

const Router: React.FC = () => {
  const application = useAppSelector(selectApplicationMode);

  return (
    <Suspense fallback={<Loading />}>
      {application === 'kiosk' ? (
        <IonReactRouter>
          <KioskRoutes />
        </IonReactRouter>
      ) : application === 'fixed_route' ? (
        <IonReactRouter>
          <FixedRoutes />
        </IonReactRouter>
      ) : null}
    </Suspense>
  );
};

export default Router;
