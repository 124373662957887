import { Theme } from '../../../store/reducers/themeReducer';
import googleMapStyles from '../../map/googleMapStyles.json';
import LiftangoLogo from '../../../assets/liftango.svg';

export const THEME: Theme = {
  name: 'liftango',
  map: googleMapStyles,
  colors: {
    primary: '#ee7334',
    primaryRgb: '238, 115, 52',
    secondary: '#3a3a48',
    secondaryRgb: '58, 58, 72',
    accent: '#74479f',
  },
  logo: LiftangoLogo,
  favicon: 'favicon.png',
  requireCssFilePath: () => require('./default.css'),
};
