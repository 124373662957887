import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FixedRoute } from '@liftango/ops-client';
import type { RootState } from '../global';

export type RoutesState = {
  hasListInitialised: boolean;
  list: FixedRoute.ServiceRoutesAndStopsPayload[];
  hidden: string[];
};

const initialState: RoutesState = {
  hasListInitialised: false,
  list: [],
  hidden: [],
};

export const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRoutes: (state: RoutesState, action: PayloadAction<FixedRoute.ServiceRoutesAndStopsPayload[]>) => {
      state.list = action.payload.sort((a, b) => a.label.localeCompare(b.label));
      state.hasListInitialised = true;
    },
    setHiddenRoutes: (state: RoutesState, action: PayloadAction<string[]>) => {
      state.hidden = action.payload;
    },
    hideRoute: (state: RoutesState, action: PayloadAction<string>) => {
      state.hidden = [...state.hidden, action.payload];
    },
    unhideRoute: (state: RoutesState, action: PayloadAction<string>) => {
      state.hidden = [...state.hidden.filter((routeId: string) => routeId !== action.payload)];
    },
  },
});

export const { setRoutes, setHiddenRoutes, hideRoute, unhideRoute } = routesSlice.actions;

export const selectRoutes = (state: RootState): FixedRoute.ServiceRoutesAndStopsPayload[] => state.routes.list;

export const selectFilteredRoutes = (state: RootState): FixedRoute.ServiceRoutesAndStopsPayload[] =>
  state.routes.list.filter((route: FixedRoute.ServiceRoutesAndStopsPayload) => !state.routes.hidden.includes(route.id));

export const selectRouteShapes = (state: RootState): FixedRoute.ServiceRouteShape[] =>
  selectFilteredRoutes(state).map((route: FixedRoute.ServiceRoutesAndStopsPayload) => route.routeShape);

export const selectStops = (state: RootState): FixedRoute.ServiceRouteStop[] =>
  selectFilteredRoutes(state)
    .map((route: FixedRoute.ServiceRoutesAndStopsPayload) => route.stops)
    .reduce((previous: FixedRoute.ServiceRouteStop[], current: FixedRoute.ServiceRouteStop[]) => [...previous, ...current], []);

export const selectRouteById = (routeVersionId: string) => (state: RootState): FixedRoute.ServiceRoutesAndStopsPayload | null =>
  state.routes.list.find((route: FixedRoute.ServiceRoutesAndStopsPayload) => route.id === routeVersionId) || null;

export const selectRoutesInitialisationState = (state: RootState): boolean => state.routes.hasListInitialised;

export const selectHiddenRoutes = (state: RootState): string[] => state.routes.hidden;

export default routesSlice.reducer;
