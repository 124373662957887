import { Theme } from '../../../store/reducers/themeReducer';
import googleMapStyles from '../../map/googleMapStyles.json';
import LiftangoLogo from '../../../assets/liftango.svg';

export const THEME: Theme = {
  name: 'tvp',
  map: googleMapStyles,
  colors: {
    primary: '#000',
    primaryRgb: '0, 0, 0',
    secondary: '#75787B',
    secondaryRgb: '117,120,123',
    accent: '#74479f',
  },
  logo: LiftangoLogo,
  favicon: 'tvp-favicon.png',
  requireCssFilePath: () => require('./tvp.css'),
};
