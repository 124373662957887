import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../global';

export type ApplicationMode = 'kiosk' | 'fixed_route';

type ApplicationFeatureToggles = {
  enableLuggage?: boolean;
};

type ApplicationState = {
  mode: ApplicationMode;
  featureToggles: ApplicationFeatureToggles;
};

const initialState: ApplicationState = {
  mode: 'fixed_route',
  featureToggles: {
    enableLuggage: false,
  },
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationMode: (state: ApplicationState, action: PayloadAction<ApplicationMode>) => {
      state.mode = action.payload;
    },
    setApplicationFeatureToggles: (state: ApplicationState, action: PayloadAction<ApplicationFeatureToggles>) => {
      state.featureToggles = action.payload;
    },
  },
});

export const { setApplicationMode, setApplicationFeatureToggles } = applicationSlice.actions;

export const selectApplicationMode = (state: RootState) => state.application.mode;

export const selectApplicationFeatureToggles = (state: RootState) => state.application.featureToggles;

export default applicationSlice.reducer;
