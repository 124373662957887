import React, { createContext, useContext, Dispatch } from 'react';
import { MapAction, MapReducerState } from '../components/features/Map/mapReducer';

export type MapContextProps = {
  mapState: MapReducerState;
  dispatch: Dispatch<MapAction>;
};

export const MapContext = createContext({} as MapContextProps);

export const useMapContext = () => {
  return useContext(MapContext);
};
